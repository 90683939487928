.ContactUs {
  width: 100%;
  height: 100vh;

  .details {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
      text-align: left;
      margin: 0 0 2rem 3rem;
      font-weight: bold;
    }
    
    h3 {
      text-align: left;
      margin: 0 0 2rem 3rem;
      font-weight: bold;
    }

    > p {
      text-align: left;
      margin-left: 2rem;
      font-size: 0.9rem;
    }

    form {
      width: 100%;
      padding: 2rem;

      > p {
        font-size: 0.9rem;
        text-align: left;
        margin-bottom: 2rem;
      }
    
      .apply-btn {
        margin: 1rem 0 0 1rem;
        background: rgba(0,0,0,0.8);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        padding: 0.5rem 1rem;
        border-radius: unset;
        border: unset;
        letter-spacing: 0.1rem;
        font-size: 1.2rem;
        color: silver;
        float: left;
        &:enabled:hover {
          color: #62B6CB;
          text-decoration: none;
        }
        &:disabled {
          background-color: #808080;
          cursor: not-allowed;
        }
      }
    }
  }

  iframe {
    height: 100vh;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .ContactUs {
    height: unset;

    .details {
      background-image: unset;
      height: unset;
    }

    iframe {
      height: 50vh;
    }
  }
}