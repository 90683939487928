.truck {
  margin: .5rem 0;
  padding: 1rem;
  border: 1px solid silver;

  img {
    height: 250px;
    object-fit: cover;
    cursor: pointer;
  }

  .details {
    text-align: left;
    padding: .5rem 1rem;
    h3 {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 0.9rem;
    }
  }

  .price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > p {
      margin-bottom: 0;
    }

    .contact-us a {
      display: inline-block;
      margin: 1rem 0 0 0;
      background: rgba(0,0,0,0.8);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      padding: 0.5rem 1rem;
      letter-spacing: 0.1rem;
      font-size: 1.2rem;
      color: silver;
      &:hover {
        color: #62B6CB;
        text-decoration: none;
      }
    }
  }
}