.banner {
  width: 100%;
  height: 100vh;
 
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 991px) {
  .banner img {
    object-position: 21%;
  }
}