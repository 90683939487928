.RepairShop {
  // padding: 10vh 0;
  width: 100%;
  height: 100vh;

  .description {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1, h3 {
      padding-left: .5rem;
      width: 100%;
      text-align: start;
      font-weight: bold;
    }

    h1{
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.9rem;
      text-align: justify;
    }

    .contact-us a {
      margin: 1rem 0 0 1rem;
      background: rgba(0,0,0,0.8);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      padding: 0.5rem 1rem;
      letter-spacing: 0.1rem;
      font-size: 1.2rem;
      color: silver;
      &:hover {
        color: #62B6CB;
        text-decoration: none;
      }
    }
  }

  .image {
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

    }
  }
}

@media screen and (max-width: 991px) {
  .RepairShop {
    padding: 10px 0;
    flex-direction: column-reverse;
    height: unset;

    .image {
      height: 30vh;
    }

    .description {
      p {
        font-size: 0.8rem;
      }
    }
  }
}