body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    // 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    // sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    // border-radius: .5rem;
    background-color: white;
  }

  &::-webkit-scrollbar{
    width: .5rem;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    // border-radius: .5rem;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #333;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
