.Footer {
  width: 100%;
  background: rgba(0,0,0,0.8);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);

  p {
    font-size: 0.9rem;
    color: silver;
    padding: 0.5rem 0;
    margin: 0;
  }
}