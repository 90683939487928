.TrucksOnSaleN {

  .trucks {
    height: calc(100vh - 75px);
    overflow: auto;

    &::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      // border-radius: .5rem;
      background-color: white;
    }

    &::-webkit-scrollbar{
      width: .5rem;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      // border-radius: .5rem;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #333;
    }
  }

  .description {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    h1, h3 {
      padding-left: .5rem;
      width: 100%;
      text-align: start;
      font-weight: bold;
    }

    h1{
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.9rem;
      text-align: justify;
    }
  }
}

@media screen and (max-width: 991px) {
  .TrucksOnSaleN {
    flex-direction: column-reverse;
    .trucks {
      height: 100%;
      overflow: unset;
    }
  }
}